<template>
  <app-page v-if="$router.currentRoute.value.name === 'home'">
    <div
      v-if="userInfo && !isDisableLK"
      class="page__aside page__aside_visible-m"
    >
      <div class="page__aside-balance balance-widget">
        <div class="balance-widget__top">
          <header class="balance-widget__title">
            Спутниковое ТВ
          </header>
          <div class="balance-widget__date">
            Баланс на {{ timeFormatter(startAppDate) }}
          </div>
          <div class="balance-widget__value">
            <div class="balance-widget__whole">
              {{ currencyFormatter(userInfo.balance.value, 1) }}{{ currencyFormatter(userInfo.balance.value, 2) }}
            </div>{{ currencyFormatter(userInfo.balance.value, 3) }} ₽
          </div>
        </div>
        <div class="balance-widget__buttons">
          <button
            class="button button_primary button_large balance-widget__button balance-widget__button_topup"
            @click.prevent="openPayWidget(userInfo); gtm.push_events({'event': 'vntMain', 'eventCategory': 'main', 'eventAction': 'button_click', 'eventLabel': 'popolnit'})"
          >
            Пополнить
          </button>
          <button
            class="button button_secondary-bg-gray button_large balance-widget__button balance-widget__button_autopayment"
            @click.prevent="toAutoPay"
          >
            Автоплатеж
          </button>
        </div>
      </div>

      <div class="page__aside-banners">
        <banner-list @replace="replaceEquipment" />
      </div>
    </div>
    <div
      v-if="userInfo && !isDisableLK"
      class="page__content page__content_regular"
    >
      <div class="blocks page__content-blocks">
        <div
          v-if="!loading && !resourcesWithDisplayName.length"
          class="blocks__item blocks__item_stacked"
        >
          <p>Уважаемый абонент!</p>
          <p>На данном договоре нет подключенных услуг.</p>
          <p>По возникшим вопросам Вы можете обратиться в службу поддежки по номеру 8 800 250 2320.</p>
        </div>

        <div
          v-if="installmentInfo"
          class="blocks__item blocks__item_stacked"
        >
          <header class="blocks__item-heading blocks__item-heading_with-extra">
            <div class="blocks__item-heading-inner">
              <div class="page__nav-title blocks__item-title blocks__item-title_large">
                Рассрочка за оборудование
              </div>
              <div class="blocks__item-service-rate service-rate">
                <div class="service-rate__value">
                  {{ installmentInfo.paySum }} ₽
                </div>
                <div class="service-rate__description">
                  Ближайший платеж {{ dateFormatter(installmentInfo.datePay, 3) }}
                </div>
              </div>
            </div>
            <div class="blocks__item-heading-extra">
              <a
                class="blocks__item-heading-extra-btn blocks__item-heading-extra-btn_info"
                href="#"
                @click.prevent="$router.push({name: 'card'})"
              >
                <span class="blocks__item-heading-extra-btn-text">Подробности</span>
              </a>
            </div>
          </header>
        </div>

        <div
          v-if="resourcesWithDisplayName.length"
          class="blocks__item blocks__item_stacked"
        >
          <div
            v-if="selectedRoot && resourcesWithDisplayName.length > 1"
            class="blocks__item-resource-selector"
          >
            <div class="form">
              <div class="form__input-field form__input-field_fixed">
                <div class="input-field">
                  <label
                    class="input-field__label"
                    for="resource"
                  />
                  <div
                    class="input-field__dropdown-box"
                    @click="toggle.switch"
                  >
                    <div class="input-field__dropdown-value-container">
                      <input
                        id="resource"
                        v-model="selectedRoot.displayName"
                        class="input-field__dropdown-value"
                        type="text"
                      >
                    </div>
                    <div :class="['input-field__dropdown-list-container', {'input-field__dropdown-list-container_visible': toggle.is(true)}]">
                      <div class="input-field__dropdown-list">
                        <div
                          v-for="root in resourcesWithDisplayName"
                          :key="root.resource.resId"
                          :class="['input-field__dropdown-list-item', {'input-field__dropdown-list-item_selected': selectedRoot.resource.resId === root.resource.resId}]"
                          @click="onRootItemClick(root)"
                        >
                          {{ root.displayName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="input-field__description" />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="tasksPlannedFirstSorted"
            class="tertiary-banner blocks__item-tertiary-banner blocks__item-tertiary-banner_mobile"
          >
            Запланирован переход на тариф «{{ tasksPlannedFirstSorted.tariffTo }}». Ожидайте SMS-уведомления.
          </div>
          <div
            v-else-if="forSignedDocuments.length"
            class="tertiary-banner banner-warning documents__warning"
          >
            У вас есть документ для подписания. <router-link
              to="/settings/documents"
              class="link"
            >
              Узнать подробнее
            </router-link>
          </div>

          <header
            v-if="selectedRoot"
            class="blocks__item-heading blocks__item-heading_with-extra"
          >
            <div class="blocks__item-heading-inner">
              <div
                v-if="tasksPlannedFirstSorted"
                class="tertiary-banner blocks__item-tertiary-banner blocks__item-tertiary-banner_desktop"
              >
                Запланирован переход на тариф «{{ tasksPlannedFirstSorted.tariffTo }}». Ожидайте SMS-уведомления.
              </div>
              <div class="page__nav-title blocks__item-title blocks__item-title_large">
                Ваш тариф
              </div>
              <div class="blocks__item-description description">
                {{ selectedRoot.resource.tariff.name }}
              </div>
              <!--                <div class="blocks__item-service-rate service-rate" v-if="selectedRootPrice !== '-' && !isRealConvergent">-->
              <!--                  <div class="service-rate__value-box">-->
              <!--                    <div class="service-rate__value">{{ selectedRootPrice.discountPrice }} ₽/{{ tariffPeriodFormatter(selectedRoot.resource.tariff.period, selectedRoot.resource.tariff.periodUnit) }}</div>-->
              <!--                    <div class="service-rate__value-old blocks__item-service-rate-value-old" v-if="selectedRootPrice.discountPrice < selectedRootPrice.price">{{ selectedRootPrice.price }} ₽</div>-->
              <!--                  </div>-->
              <!--                  &lt;!&ndash; <div class="service-rate__description description">Списание будет {{ dateFormatter(selectedRoot.resource.nextRegularPay) }}</div> &ndash;&gt;-->
              <!--                </div>-->
            </div>
            <div class="blocks__item-heading-extra">
              <a
                class="blocks__item-heading-extra-btn blocks__item-heading-extra-btn_description"
                href="#"
                @click.prevent="$router.push({ name: 'tariffs', params: { resId: selectedRoot.resource.resId } }); gtm.push_events({'event': 'vntMain', 'eventCategory': 'main', 'eventAction': 'button_click', 'eventLabel': 'smenit_tarif'})"
              ><span class="blocks__item-heading-extra-btn-text">Сменить тариф</span>
              </a>
            </div>
          </header>

          <div
            v-if="selectedRoot"
            class="blocks__item-smarcards"
          >
            <div
              v-for="(smartCard, index) in smartCardsSorted"
              :key="smartCard.resId"
              class="smartcard"
            >
              <div class="smartcard__heading">
                <div
                  v-if="smartCardsSorted.length > 1"
                  class="smartcard__title"
                >
                  Смарт-карта № {{ smartCard.cardId }}
                </div>
                <div
                  v-if="selectedRoot.resource.inMultiroom"
                  :class="['smartcard__type', {'smartcard__type_main': smartCard.mainMultiroom}]"
                >
                  {{ smartCard.mainMultiroom ? "Основная": "Мультирум" }}
                </div>
              </div>
              <div class="smartcard__items-container">
                <div
                  :ref="el => {if(el) cardContainers[index] = el}"
                  class="smartcard__items"
                  @scroll="onScroll($event, index)"
                >
                  <div
                    v-for="pack in smartCard.children"
                    :key="pack.resId"
                    class="smartcard__item"
                  >
                    <div class="smartcard__item-title">
                      <span class="smartcard__item-title-text">{{ pack.tariff.name }}</span>
                      <app-tooltip-new
                        v-if="pack.tariff.description1.trim()"
                        tooltip-position="top"
                        :scroll-left="scrollLeft"
                      >
                        {{ pack.tariff.description1 }}
                      </app-tooltip-new>
                    </div>
                    <div class="smartcard__item-rate">
                      <div class="service-rate">
                        <div class="service-rate__value-box smartcard__item-rate-value-box">
                          <div class="service-rate__value smartcard__item-rate-value">
                            {{ pack.tariff.discount.price }} ₽/{{ tariffPeriodFormatter(pack.tariff.period, pack.tariff.periodUnit) }}
                          </div>
                          <div
                            v-if="pack.tariff.discount.price < pack.tariff.price"
                            class="service-rate__value-old smartcard__item-rate-value-old"
                          >
                            {{ pack.tariff.price }} ₽
                          </div>
                        </div>
                        <div
                          v-if="getBlockedStatus(pack).status === 0"
                          class="service-rate__description"
                        >
                          Cписание будет {{ dateFormatter(pack.nextRegularPay) }}
                        </div>
                        <div
                          v-if="inTariff(pack, smartCard)"
                          :class="['service-rate__description', getBlockedStatus(pack).status===0 ? 'equipment__status': 'service-rate__description_attention']"
                        >
                          {{ getBlockedStatus(pack).status===0? getBlockedStatus(pack).forDeveloperDescription : getBlockedStatus(pack).text }}
                        </div>
                        <div
                          v-else
                          class="service-rate__description service-rate__description_attention"
                        >
                          {{ getBlockedStatus(pack).text }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    v-if="(selectedRoot.resource.inMultiroom && smartCard.mainMultiroom) || !selectedRoot.resource.inMultiroom"
                    class="smartcard__item smartcard__item-add"
                    href="#"
                    @click.prevent="packAddClicked(smartCard)"
                  />
                </div>
                <div
                  v-if="smartCard.children.length > 2 && isDesktop && !cardContainerScrolls[index].scrollStarted"
                  class="smartcard__items-nav smartcard__items-nav_prev"
                  @click="scrollPrev(index)"
                />
                <div
                  v-if="smartCard.children.length > 2 && isDesktop && !cardContainerScrolls[index].scrollEnded"
                  class="smartcard__items-nav smartcard__items-nav_next"
                  @click="scrollNext(index)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="blocks__item blocks__item_mobile">
          <h1 class="page__nav-title blocks__item-title blocks__item-title_large">
            Добавьте услуги
          </h1>
          <div class="blocks__item-content">
            <banner-list
              :inline="true"
              @replace="replaceEquipment"
            />
          </div>
        </div>

        <div
          v-if="selectedRoot"
          class="blocks__item blocks__item_stacked-tablet"
        >
          <div class="blocks__item_stacked-tablet-header">
            <h1 class="page__nav-title blocks__item-title blocks__item-title_large">
              Оборудование
            </h1>
            <div class="blocks__item_stacked-tablet-header-button">
              <button
                class="button__icon-top button__icon-top-extra button__icon-top-pen blocks__item_stacked-tablet-header-button-replace"
                @click="replaceEquipment(true)"
              >
                Заменить оборудование
              </button>
            </div>
          </div>
          <div class="blocks__item-content">
            <table class="table equipment equipment__table">
              <thead class="table-header equipment__table-header">
                <tr class="table-row equipment__table-row">
                  <td class="table__header-col">
                    Название
                  </td>
                  <td class="table__header-col">
                    Серийный номер
                  </td>
                  <td class="table__header-col equipment__status-header">
                    Статус
                  </td>
                </tr>
              </thead>
              <tbody class="equipment__table-body">
                <tr
                  v-for="eq in equipments"
                  :key="eq.resId"
                  class="table-row equipment__item table-row-body"
                >
                  <td :class="['table-col', 'equipment__title', eq.eqType==='stb' ? 'equipment__title_console': 'equipment__title_card' ]">
                    {{ eq.eqType==='stb' ? 'Приставка': 'Смарт-карта' }}
                  </td>
                  <td class="table-col equipment__serial">
                    <div class="equipment__serial-label">
                      Серийный номер
                    </div>{{ eq.eqType==='stb' ? eq.chipId: eq.cardId }}
                  </td>
                  <td :class="['table-col', 'equipment__status', {'equipment__status_disabled': eq.statusBasicChanel}]">
                    <p class="status__stb-mobile-pos">
                      {{ !eq.statusBasicChanel ? 'Подключено' : 'Отключено' }}
                    </p>
                    <div
                      v-if="!eq.statusBasicChanel && eq.eqType==='stb'"
                      class="table__equipment-switch-mobile"
                    >
                      <switch-equipment
                        :equipment="eq"
                        @activated="activatedEquipment"
                      />
                    </div>
                  </td>
                  <td
                    v-if="!eq.statusBasicChanel && eq.eqType==='stb'"
                    class="table-col equipment__switch"
                  >
                    <div class="table__equipment-switch-desktop">
                      <AppButton
                        class="activated__button"
                        :disabled="eq.isActiveTimer"
                        @click="activatedEquipment(eq)"
                      >
                        Активировать
                      </AppButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AppModal
      v-if="isActivatedEquipment"
      :view-close="windowSize > 767"
      @close="isActivatedEquipment = false"
    >
      <ActivatedEquipment
        :equipment="activeEquipment"
        @close="isActivatedEquipment = false"
      />
    </AppModal>

    <AppModal
      v-if="isEquipmentReplaceView"
      :mobile-full-view="true"
      :not-padding="true"
      @close="isEquipmentReplaceView = false"
    >
      <Suspense>
        <template #default>
          <ReplaceEquipment
            :title="isEquipmentReplace ? 'Заказ оборудования': 'Отправить заявку'"
            :is-replace="isEquipmentReplace"
            :selected-root="selectedRoot"
            @close="isEquipmentReplaceView = false"
          />
        </template>
        <template #fallback>
          <div class="loader__wrapper">
            <AppLoader size="i" />
          </div>
        </template>
      </Suspense>
    </AppModal>

    <task-cancel-modal
      v-if="taskCancelModal"
      :task="tasksPlannedFirstSorted"
      :res-name="selectedRoot.resource.resName"
      @success="taskCancelModal = false; triggerToast('Смена тарифного плана отменена', 'ok'); gtm.push_events({'event': 'vntMain', 'eventCategory': 'smena_tarifa', 'eventAction': 'confirmed', 'eventLabel': 'uspeshnaya_otmena', 'actionGroup': 'conversions'})"
      @cancel="taskCancelModal = false"
      @error="taskCancelModal = false; triggerToast('Произошла ошибка', 'error')"
    />
    <app-toast
      v-if="showToast"
      :text="toastText"
      :type="toastType"
    />
  </app-page>
  <router-view v-else />
</template>

<script>

import { computed, onMounted, onBeforeUpdate, ref, watch, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AppPage from '@/components/ui/AppPage.vue';
import { currencyFormatter, timeFormatter, dateFormatter, tariffPeriodFormatter } from '@/utils/helpers';
import BannerList from '@/components/TheBannerList.vue';
import AppToast from '@/components/ui/AppToast.vue';
import { useToggle } from '@/hooks/toggle';

import { useWindowSize } from '@/hooks/window-size';
import { useToast } from '@/hooks/toast';
import AppTooltipNew from '@/components/ui/AppTooltipNew.vue';
import TaskCancelModal from '@/components/tariffs/modals/TaskCancelModal.vue';
import { useGTM } from '@/hooks/gtm';
import { openPayWidget } from '@/utils/openPayWidget';
import AppButton from '@/components/ui/AppButton.vue';
import SwitchEquipment from '@/components/home/switchEquipment.vue';
import AppModal from '@/components/ui/AppModal.vue';
import ActivatedEquipment from '@/components/home/modals/activatedEquipment.vue';
import AppLoader from '@/components/ui/AppLoader.vue';
const ReplaceEquipment = defineAsyncComponent(() => import('@/components/home/modals/replaceEquipment.vue'));

export default {
  components: { ReplaceEquipment, AppLoader, ActivatedEquipment, AppModal, SwitchEquipment, AppButton, AppPage, BannerList, AppTooltipNew, TaskCancelModal, AppToast },
  setup () {
    const gtm = useGTM();

    const BASE_PACK_SL = 'ctv-base-pack_sl';
    const SEASON_PACK_SL = 'ctv-seasonal-pack_sl';

    const router = useRouter();
    const store = useStore();

    const forSignedDocuments = computed(() => store.getters['settings/forSignedDocuments']);

    const toast = useToast();

    const { toggle } = useToggle(false);

    const { isDesktop, windowSize } = useWindowSize();
    const isDisableLK = store.getters['cms/getIsDisableLK'];
    const qrCode = store.getters['cms/getQrCode'];
    const startAppDate = computed(() => store.getters.startAppDate);
    const userInfo = computed(() => store.getters['user/userInfo']);
    const isRealConvergent = computed(() => store.getters['user/needShowConvergent']);

    const installmentInfo = computed(() => store.getters['installment/installmentInfo']);

    const resources = computed(() => store.getters['resource/resources']);
    const resourcesWithDisplayName = ref([]);

    const selectedRoot = ref(null);

    const smartCards = ref([]);
    const stbs = ref([]);

    const isActivatedEquipment = ref(false);
    const activeEquipment = ref(null);

    const isEquipmentReplaceView = ref(false);
    const isEquipmentReplace = ref(false);

    const replaceEquipment = (flag) => {
      isEquipmentReplaceView.value = true;
      isEquipmentReplace.value = flag;
    };

    const equipments = computed(() => {
      const result = [];
      stbs.value.forEach(stb => {
        const activeTimer = JSON.parse(window.localStorage.getItem(`${stb.resId}` || null));
        let isRangeTimerOf5Min = false;
        if (activeTimer) {
          const oldDate = new Date(activeTimer);
          isRangeTimerOf5Min = (new Date() - oldDate < 300000);
        }
        if (!isRangeTimerOf5Min) window.localStorage.removeItem(`${stb.resId}`);

        let statusBasicChanel = false;
        for (let i = 0; i < stb.children[0].children.length; i++) {
          if (stb.children[0].children[i].resType === BASE_PACK_SL) {
            statusBasicChanel = stb.children[0].children[i].disabled;
          }
        }

        result.push({ ...stb, eqType: 'stb', statusBasicChanel, isActiveTimer: !!(isRangeTimerOf5Min) });
        result.push({ ...stb.children[0], statusBasicChanel, eqType: 'card' });
      });
      return result;
    });

    const packPriority = [BASE_PACK_SL, SEASON_PACK_SL]; // приоритет сортировки пакетов

    const smartCardsSorted = computed(() => {
      const smartCardsSorted = smartCards.value.sort((a, b) => (b.mainMultiroom - a.mainMultiroom) || (b.resId - a.resId));
      smartCardsSorted.forEach(card => {
        card.children.sort((a, b) => (b.resType === packPriority[0]) - (a.resType === packPriority[0]) || (b.resType === packPriority[1]) - (a.resType === packPriority[1]));
      });
      return smartCardsSorted;
    });

    const buildMultiroom = () => {
      // Анализируем мультирум. Пробигаемся по всем STB, и если на какой-то есть признак главной, то корневому ресурсу проставляем: inMultiroom,
      // а дочерней к такой STB смарт-карте обновляем MainMultiroom

      resources.value.forEach(function (current) {
        current.inMultiroom = false;
      });

      for (let ri = 0; ri < resources.value.length; ri++) {
        const root = resources.value[ri]; // корневой ресурс
        const stbs = root.children;
        for (let stbi = 0; stbi < stbs.length; stbi++) {
          const stb = stbs[stbi]; // приставка
          if (stb.mainMultiroom) {
            root.inMultiroom = true;
            const cards = stb.children;
            for (let cardi = 0; cardi < cards.length; cardi++) {
              const smartCard = cards[cardi]; // карта
              smartCard.mainMultiroom = true;
            }
            break;
          }
        }
      }
    };

    const cardContainers = ref([]);
    const cardContainerScrolls = ref([]);

    watch(selectedRoot, () => {
      if (!selectedRoot.value) {
        return;
      }
      smartCards.value = [];
      cardContainerScrolls.value = [];

      stbs.value = selectedRoot.value.resource.children;
      stbs.value.forEach(stb => stb.children.forEach(smartCard => smartCards.value.push(smartCard)));

      smartCardsSorted.value.forEach((current, idx) => {
        cardContainerScrolls.value.push({ index: idx, scrollEnded: false, scrollStarted: true });
      });

      try {
        updatePlannedTariffChanges();
      } catch (e) { }
    });

    const loading = ref(true);

    onMounted(async () => {
      try {
        loading.value = true;

        if (!installmentInfo.value && installmentInfo.value !== '') {
          store.dispatch('installment/load').catch(() => {});
        }

        // if (!resources.value) {
        //   await store.dispatch('resource/load');
        // }

        buildMultiroom();

        resourcesWithDisplayName.value = resources.value.map((resource) => { return { resource: resource, displayName: rootDisplayName(resource) }; });
        selectedRoot.value = resourcesWithDisplayName.value[0];
      } catch (e) {
      } finally {
        loading.value = false;
      }
    });

    const tasksPlanned = computed(() => store.getters['tariffs/plannedChanges']);

    const tasksPlannedFirstSorted = computed(() => {
      return tasksPlanned.value?.resId !== selectedRoot.value.resource.resId
        ? null
        : tasksPlanned.value.data
          .sort((a, b) => {
            if (a.date > b.date) {
              return 1;
            }
            if (a.date < b.date) {
              return -1;
            }
            return 0;
          })[0];
    });

    const taskCancelModal = ref(false);

    const updatePlannedTariffChanges = async () => {
      if (!tasksPlanned.value || tasksPlanned.value.resId !== selectedRoot.value.resource.resId) {
        const payload = { resId: selectedRoot.value.resource.resId };
        await store.dispatch('tariffs/plannedTariffChangesLoad', payload);
      }
    };

    onBeforeUpdate(() => {
      cardContainers.value = [];
    });

    const rootDisplayName = (root) => {
      return root.fullname + ' - ' + root.resName;
    };

    const onRootItemClick = (root) => {
      selectedRoot.value = root;
    };

    const scrollLeft = ref(0);

    const onScroll = (e, idx) => {
      scrollLeft.value = e.target.scrollLeft;
      cardContainerScrolls.value[idx].scrollEnded = scrollEnded(idx);
      cardContainerScrolls.value[idx].scrollStarted = scrollStarted(idx);
    };

    const scrollEnded = (idx) => {
      const elem = cardContainers.value[idx];
      const elemWidth = elem.getBoundingClientRect().width;
      const maxScrollWidth = elem.scrollWidth - elemWidth;
      return elem.scrollLeft >= maxScrollWidth;
    };

    const scrollStarted = (idx) => {
      const elem = cardContainers.value[idx];
      return elem.scrollLeft === 0;
    };

    const scrollNext = (idx) => {
      cardContainers.value[idx].scrollLeft += 290;
    };

    const scrollPrev = (idx) => {
      cardContainers.value[idx].scrollLeft -= 290;
    };

    const blockStatus = [
      { status: 0, text: '', forDeveloperDescription: 'Услуга активна' },
      { status: 1, text: 'Услуга отключена. Добровольная блокировка' },
      { status: 2, text: 'Услуга отключена. Финансовая блокировка' },
      { status: 3, text: 'Услуга отключена' }
    ];

    const getBlockedStatus = (pack) => {
      if (!pack.disabled) {
        return blockStatus[0];
      }

      if (pack.blocked && pack.blockDate) {
        return blockStatus[1];
      }

      if (!pack.blocked) {
        return blockStatus[2];
      }

      return blockStatus[3];
    };

    const packAddClicked = (card) => {
      gtm.push_events({ event: 'vntStv', eventCategory: 'glavnaya', eventAction: 'button_click', eventLabel: 'dobavit', buttonLocation: 'screen' });
      const params = { stv: selectedRoot.value.resource.resId, card: card.resId };
      router.push({ name: 'services', query: params });
    };

    const resTypesForRootPrice = [BASE_PACK_SL, SEASON_PACK_SL];

    const selectedRootPrice = computed(() => {
      const errorResult = '-';
      const tariffPeriod = { period: selectedRoot.value.resource.tariff.period, periodUnit: selectedRoot.value.resource.tariff.periodUnit };
      const result = {
        price: 0,
        discountPrice: 0
      };

      function tariffPeriodEquil (tariffPeriod1, tariffPeriod2) {
        return tariffPeriod1.period === tariffPeriod2.period && tariffPeriod1.periodUnit === tariffPeriod2.periodUnit;
      }

      function getTariffPeriod (resource) {
        return { period: resource.tariff.period, periodUnit: resource.tariff.periodUnit };
      }

      function getPriceByStb (stb) {
        const result = {
          price: 0,
          discountPrice: 0
        };
        if (!tariffPeriodEquil(tariffPeriod, getTariffPeriod(stb))) {
          return errorResult;
        }
        result.price += stb.tariff.price;
        result.discountPrice += stb.tariff.discount.price;
        const mainCard = stb.children[0];
        if (!tariffPeriodEquil(tariffPeriod, getTariffPeriod(mainCard))) {
          return errorResult;
        }
        result.price += mainCard.tariff.price;
        result.discountPrice += mainCard.tariff.discount.price;
        // Пробегаемся по нужным пакетам каналов и добавляем их стоимость
        const packs = mainCard.children.filter(pack => resTypesForRootPrice.includes(pack.resType));
        if (packs.find(pack => !tariffPeriodEquil(tariffPeriod, getTariffPeriod(pack)))) {
          return errorResult;
        }
        packs.forEach(pack => {
          result.price += pack.tariff.price;
          result.discountPrice += pack.tariff.discount.price;
        });
        return result;
      }

      // цена корневого ресурса
      result.price += selectedRoot.value.resource.tariff.price;
      result.discountPrice += selectedRoot.value.resource.tariff.discount.price;

      // если есть мультирум у этого корневого ресурса
      if (selectedRoot.value.resource.inMultiroom) {
        // находим главную приставку:
        const mainStb = selectedRoot.value.resource.children.find(stb => stb.mainMultiroom === true);
        const stbPrice = getPriceByStb(mainStb);
        if (stbPrice === errorResult) {
          return errorResult;
        }
        result.price += stbPrice.price;
        result.discountPrice += stbPrice.discountPrice;
      } else {
        for (let i = 0; i < selectedRoot.value.resource.children.length; i++) {
          const stb = selectedRoot.value.resource.children[i];
          const stbPrice = getPriceByStb(stb);
          if (stbPrice === errorResult) {
            return errorResult;
          }
          result.price += stbPrice.price;
          result.discountPrice += stbPrice.discountPrice;
        }
      }
      return result;
    });
    const inTariff = (pack, card) => {
      return selectedRoot.value.resource.inMultiroom && card.mainMultiroom && packPriority.includes(pack.resType) ||
        !selectedRoot.value.resource.inMultiroom && packPriority.includes(pack.resType);
    };

    const toAutoPay = () => {
      gtm.push_events({ event: 'vntAvto', eventCategory: 'avtoplatezh', eventAction: 'button_click', eventLabel: 'nastroit_avtoplatezh' });
      const url = 'https://payment.mts.ru/aps?tab=Aps';
      window.open(url, '_blank');
    };

    const activatedEquipment = (equipment) => {
      isActivatedEquipment.value = true;
      activeEquipment.value = equipment;
    };

    return {
      startAppDate,
      toggle,
      userInfo,
      installmentInfo,
      resourcesWithDisplayName,
      selectedRoot,
      tasksPlannedFirstSorted,
      taskCancelModal,
      ...toast,
      onRootItemClick,
      smartCardsSorted,
      loading,
      logout: () => {
        store.commit('auth/logout');
        router.push('/auth');
      },
      currencyFormatter,
      timeFormatter,
      dateFormatter,
      tariffPeriodFormatter,
      isEquipmentReplace,
      replaceEquipment,
      isEquipmentReplaceView,
      cardContainers,
      cardContainerScrolls,
      onScroll,
      scrollNext,
      scrollPrev,
      isDesktop,
      equipments,
      getBlockedStatus,
      scrollLeft,
      packAddClicked,
      selectedRootPrice,
      inTariff,
      isRealConvergent,
      gtm,
      forSignedDocuments,
      toAutoPay,
      openPayWidget,
      isActivatedEquipment,
      activatedEquipment,
      activeEquipment,
      windowSize,
      isDisableLK,
      qrCode
    };
  }

};
</script>
<style scoped>
.documents__warning {
  margin-bottom: 16px;
}

.activated__button {
  padding: 6px 8px;
  font-size: 14px;
  line-height: 20px;
  min-width: 96px;
}

.blocks__item_stacked-tablet-header {
  display: flex;
  justify-content: space-between;
}

.blocks__item_stacked-tablet-header-button {
  display: flex;
  align-items: center;
  gap: 24px;
}
.loader__wrapper {
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}
@media (max-width: 767px) {
  .blocks__item_stacked-tablet-header {
    flex-direction: column;
  }
  .blocks__item_stacked-tablet-header-button {
    margin-top: 12px;
    gap: 12px;
  }
  .blocks__item_stacked-tablet-header-button-replace {
    width: 50%;
  }
  .status__stb-mobile-pos {
    text-align: end;
  }
}
</style>
